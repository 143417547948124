/* eslint-disable */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { sortArray } from 'utils/arrayFunctions';
import cloneDeep from 'lodash/cloneDeep';
import {
	AdditionalColumns,
	AdditionalRows,
	CallReportType,
	DiffReportType,
	OptionType,
	PeriodsType,
	ReportInitialStateType,
	ReportParametersType,
	RowsGroupByParametersType,
	SelectorsValuesParametersType,
	ViewOptionsOnly,
	ViewOptionsRowsOrColumns,
	ChartType,
	ViewOptionsGlobal,
	KeysSummaries,
	ReportByHash,
} from './reports.types';
import { criteriaWithComplexValues } from 'configs/searchCriteria';
import initialState, { ViewOptionsRowsOrColumnsObj } from './initial';
import { getCriteriaWithComplexValues, removeFromComplexCriteria } from '../search/utils';
import { CriteriasType } from '../search/search.types';
import { convertDateToFormat } from 'utils/convertDateAndTime/convertDateForRequest';

export const reportsSlice = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},

		setReportLoadingIsStarted(state, action: PayloadAction<boolean>) {
			state.reportLoadingIsStarted = action.payload;
		},

		setReportWebSocket(state, action: PayloadAction<any>) {
			state.reportWebSocket = action.payload;
		},

		setLoadingReport(state, action: PayloadAction<ReportInitialStateType['loadingReport']>) {
			state.loadingReport = action.payload;
		},

		setLoadingReportInitial(state, action: PayloadAction<null>) {
			state.loadingReport = initialState.loadingReport;
		},

		setReports(state, action: PayloadAction<[]>) {
			state.allReports = action.payload;
		},
		setActiveReport(state, action: PayloadAction<ReportParametersType>) {
			const result = action.payload;
			state.activeReport = result;
			state.isSavedReport = true;

			if (result.from_dt) {
				state.date = [moment(result.from_dt, 'YYYY/MM/DD'), moment(result.to_dt, 'YYYY/MM/DD')];
			}

			// заглушка для корявых сохраненных отчетов (когда нет всех чекбоксов отображения)
			if (!result.view_options) {
				state.activeReport.view_options = initialState.activeReport.view_options;
			}
			if (!Object.keys(result.view_options).includes('show_zero_cols')) {
				state.activeReport.view_options.show_zero_cols = true;
				state.activeReport.view_options.show_zero_grouping_rows = true;
				state.activeReport.view_options.show_zero_grouping_cols = true;
			}
			// для старых отчетов, где не сохранен график передаем дефолтные значения графиков
			if (!result.view_options.chart_options) {
				state.activeReport.view_options.chart_options = initialState.activeReport.view_options.chart_options;
			}
		},
		setActiveReportByHash(state, action: PayloadAction<ReportByHash>) {
			const report = action.payload.report_parameters;
			state.activeReport = report;
			state.isSavedReport = true;
			state.isReportCollapsed = true;
			// date from hash
			const date_1 = moment(action.payload.start_date, 'YYYY-MM-DD');
			const date_2 = moment(action.payload.end_date, 'YYYY-MM-DD');

			state.date = [date_1, date_2];
			const convertedDates = convertDateToFormat([date_1, date_2]);
			state.activeReport.from_dt = convertedDates[0];
			state.activeReport.to_dt = convertedDates[1];
		},

		setIsSavedReport(state, action: PayloadAction<boolean>) {
			state.isSavedReport = action.payload;
		},
		setReportById(state, action: PayloadAction<boolean>) {
			state.isReportById = action.payload;
		},
		setReportTooBig(state, action: PayloadAction<boolean>) {
			state.isReportTooBig = action.payload;
		},

		setCurrentSavedReport(state, action: PayloadAction<OptionType>) {
			state.currentSavedReport = action.payload;
		},
		setInitialSavedReport(state) {
			state.currentSavedReport = initialState.currentSavedReport;
		},
		setReportByHash(state, action: PayloadAction<boolean>) {
			state.isReportByHash = action.payload;
		},
		setReportCollapsed(state, action: PayloadAction<boolean>) {
			state.isReportCollapsed = action.payload;
		},
		setCallReport(state, action: PayloadAction<CallReportType>) {
			state.callReport = action.payload;
		},
		setInitialCallReport(state, action: PayloadAction<null>) {
			state.callReport = initialState.callReport;
		},
		setDiffReport(state, action: PayloadAction<DiffReportType>) {
			state.diffReport = action.payload;
			if (action.payload) state.isDiffReportHide = false;
		},
		setDiffReportIsLoaded(state, action: PayloadAction<boolean>) {
			state.diffReportIsLoaded = action.payload;
		},
		setDiffReportHide(state, action: PayloadAction<boolean>) {
			state.isDiffReportHide = action.payload;
		},
		setDate(state, action: PayloadAction<typeof initialState.date | null[]>) {
			state.date = action.payload;
		},

		setFromToDate(state, action: PayloadAction<(string | null)[]>) {
			state.activeReport.from_dt = action.payload[0];
			state.activeReport.to_dt = action.payload[1];
		},

		setPeriod(state, action: PayloadAction<PeriodsType | null | string>) {
			state.activeReport.period = action.payload;
		},

		setChartType(state, action: PayloadAction<{ value: ChartType; label: string }>) {
			state.activeReport.view_options.chart_options.chart_type = action.payload.value;
		},

		setChartCheck(state, action: PayloadAction<string>) {
			state.activeReport.view_options.chart_options.by_sub_column = action.payload;
		},

		setSummariesType(state, action: PayloadAction<KeysSummaries>) {
			state.activeReport.view_options.intersection_summaries_type = action.payload;
		},

		setTagNames(state, action: PayloadAction<string[]>) {
			state.tagNames = sortArray(action.payload);
		},

		setNumTagsNames(state, action: PayloadAction<string[]>) {
			state.numTagsNames = sortArray(action.payload);
		},

		setTagNamesWithValues(state, action: PayloadAction<string[]>) {
			state.tagNamesWithValues = sortArray(action.payload);
		},

		setTagValuesColumnAdd(state, action: PayloadAction<[]>) {
			state.tagColumnsAddValues = sortArray(action.payload);
		},

		setTagValuesColumn(state, action: PayloadAction<[]>) {
			state.tagColumnValues = sortArray(action.payload);
		},

		// TODO: разобраться, почему скрыли
		// setTagValuesRow(state, action: PayloadAction<[]>) {
		// 	state.tagRowValues = sortArray(action.payload);
		// },

		getSelectorsValues(state, action: PayloadAction<{ selectors: SelectorsValuesParametersType; tags: string[] }>) {
			state.selectors = action.payload.selectors;
			state.activeReport.report_type = action.payload.selectors.report_types[0];
		},

		setValidTagList(state, action: PayloadAction<boolean>) {
			state.isValidTagList = action.payload;
		},

		// view options
		setViewOption(state, action: PayloadAction<{ name: keyof ViewOptionsGlobal; data: boolean }>) {
			state.activeReport.view_options[action.payload.name] = action.payload.data;
		},

		// clear графики
		setInitialChart(state) {
			state.activeReport.view_options = initialState.activeReport.view_options;
		},

		setResetParameters(state, action: PayloadAction<boolean>) {
			state.isResetParameters = action.payload;
		},

		// установление параметров отчета по порядку
		setNameReport(state, action: PayloadAction<string>) {
			state.activeReport.report_name = action.payload;
		},
		setTypeReport(state, action: PayloadAction<string>) {
			state.activeReport.report_type = action.payload;
		},

		// группировка строк
		setActiveRowsGroupBy(state, action: PayloadAction<RowsGroupByParametersType[]>) {
			state.activeReport.rows_group_by = action.payload;
		},

		setActiveRowAllViewOptions(state, action: PayloadAction<ViewOptionsRowsOrColumns>) {
			state.additionalRows[0].viewOptions = action.payload;
		},

		setDefaultColumn(state, action: PayloadAction<any>) {
			state.activeReport.cols_group_by[0] = action.payload;
		},

		setActiveParameters(state, action: PayloadAction<AdditionalColumns>) {
			state.activeReport.cols_group_by.push(action.payload);
		},

		setAllAdditionalColumns(state, action: PayloadAction<AdditionalColumns[]>) {
			state.activeReport.cols_group_by = action.payload;
		},

		// view options
		setParameterColumnViewOption(
			state,
			action: PayloadAction<{ name: keyof ViewOptionsOnly; data: boolean; arrayIndex: number }>,
		) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].viewOptions[action.payload.name] =
				action.payload.data;
		},

		// выбор селекта
		setParameterSelectFieldValue(
			state,
			action: PayloadAction<{ arrayIndex: number; value: { value: any; label: string } }>,
		) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].groupByColumnsValue = action.payload.value;

			state.activeReport.cols_group_by[action.payload.arrayIndex].viewOptions = ViewOptionsRowsOrColumnsObj;
		},
		// тег и значение
		setParamTag(state, action: PayloadAction<{ arrayIndex: number; value: { value: string; label: string } }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagNameValue.valueTag = action.payload.value;
		},
		setParamTagName(state, action: PayloadAction<{ arrayIndex: number; value: string }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagNameValue.name = action.payload.value;
		},
		setParamTagValues(state, action: PayloadAction<{ arrayIndex: number; value: OptionType[] }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagNameValue.optionsTag = action.payload.value;
		},
		setParamTagNameValues(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagNameValue.values = action.payload.value;
		},
		setInitialTagNameValue(state, action: PayloadAction<{ arrayIndex: number }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagNameValue.values = [];
		},
		setParamTagSplit(state, action: PayloadAction<{ arrayIndex: number; value: boolean }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagNameValue.split = action.payload.value;
		},
		setParamTagCheckboxAppropriate(state, action: PayloadAction<{ arrayIndex: number; value: boolean }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagNameValue.only_to_first_appropriate =
				action.payload.value;
		},

		//  названия столбца
		setNameColumnFieldValue(state, action: PayloadAction<{ arrayIndex: number; value: string }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].callFilters.nameColumn.value =
				action.payload.value;
		},

		setActiveCriteriaColumn(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].callFilters.activeValues =
				action.payload.criteria;
		},

		setActiveCriteriaColumn2(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].callFilters.activeValues.push(
				action.payload.criteria,
			);
		},

		setOptionsCriterias(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].callFilters.options = action.payload.criteria;
		},

		setActiveColTagsName(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagsNameList.values = action.payload.value;
		},
		setActiveColTagsColName(state, action: PayloadAction<{ arrayIndex: number; value: string }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagsNameList.col_name = action.payload.value;
		},
		setActiveColTagsSplit(state, action: PayloadAction<{ arrayIndex: number; value: boolean }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagsNameList.split = action.payload.value;
		},
		setActiveColTagsCheckboxAppropriate(state, action: PayloadAction<{ arrayIndex: number; value: boolean }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagsNameList.only_to_first_appropriate =
				action.payload.value;
		},

		setActiveCriteriaValuesColumn(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			const { arrayIndex, criteria } = action.payload;
			const obj = current(state.activeReport.cols_group_by[arrayIndex].callFilters.activeValues).find(
				(item: any) => item.key === criteria.key,
			);

			const index = current(
				state.activeReport.cols_group_by[arrayIndex].callFilters.activeValues, // @ts-ignore
			).indexOf(obj);
			if (criteriaWithComplexValues.includes(criteria.key)) {
				state.activeReport.cols_group_by[arrayIndex].callFilters.activeValues = getCriteriaWithComplexValues(
					criteria.key,
					current(state.activeReport.cols_group_by[arrayIndex].callFilters.activeValues),
					criteria,
				);
			} else {
				state.activeReport.cols_group_by[arrayIndex].callFilters.activeValues[index].values = criteria.values;
			}
		},

		removeComplexCriteriaValuesColumn(
			state,
			action: PayloadAction<{ arrayIndex: number; criteria: CriteriasType }>,
		) {
			const { arrayIndex, criteria } = action.payload;

			state.activeReport.cols_group_by[arrayIndex].callFilters.activeValues = removeFromComplexCriteria(
				current(state.activeReport.cols_group_by[arrayIndex].callFilters.activeValues),
				criteria.conditionItemId,
				criteria.key,
			);
		},

		removeActiveCriteriaColumn(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			const activeCriteriasColumn = cloneDeep(
				current(state.activeReport.cols_group_by[action.payload.arrayIndex].callFilters.activeValues),
			);
			const obj = current(
				state.activeReport.cols_group_by[action.payload.arrayIndex].callFilters.activeValues,
			).find((item: any) => item.key === action.payload.criteria.key);
			// @ts-ignore
			const index = current(
				state.activeReport.cols_group_by[action.payload.arrayIndex].callFilters.activeValues, // @ts-ignore
			).indexOf(obj);
			activeCriteriasColumn.splice(index, 1);

			state.activeReport.cols_group_by[action.payload.arrayIndex].callFilters.activeValues =
				activeCriteriasColumn;
		},

		setIsValidColumn(state, action: PayloadAction<{ arrayIndex: number; value: boolean }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].callFilters.nameColumn.isValid =
				action.payload.value;
		},

		setIsValidTagListTitle(state, action: PayloadAction<{ arrayIndex: number; value: boolean }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagsNameList.col_name_isValid =
				action.payload.value;
		},

		setIsValidTagName(state, action: PayloadAction<{ arrayIndex: number; value: boolean }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagNameValue.isValid = action.payload.value;
		},

		setIsValidTagList(state, action: PayloadAction<{ arrayIndex: number; value: boolean }>) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].tagsNameList.values_isValid =
				action.payload.value;
		},

		setTableRows(state, action: PayloadAction<any>) {
			state.tableRows = action.payload;
		},
		setTableColumns(state, action: PayloadAction<any>) {
			state.tableColumns = action.payload;
		},
		setTableAllRows(state, action: PayloadAction<any>) {
			state.tableAllRows = action.payload;
		},

		// view options
		setParametersViewOption(
			state,
			action: PayloadAction<{
				data: ViewOptionsRowsOrColumns;
				arrayIndex: number;
			}>,
		) {
			state.activeReport.cols_group_by[action.payload.arrayIndex].viewOptions = action.payload.data;
		},

		// initial state and reset
		// удаление активного доп критерия
		removeParameterField(state, action: PayloadAction<{ arrayIndex: number }>) {
			state.activeReport.cols_group_by.splice(action.payload.arrayIndex, 1);
		},
		resetDate(state) {
			state.date = initialState.date;
			state.activeReport.period = initialState.activeReport.period;
		},

		resetDiffReport(state) {
			state.diffReport = initialState.diffReport;
		},

		resetReportParameters(state) {
			state.additionalRows = initialState.additionalRows;
			state.activeReport.report_name = initialState.activeReport.report_name;
			state.activeReport.call_search_items = initialState.activeReport.call_search_items;

			// DEV-1756
			state.activeReport.cols_group_by = initialState.activeReport.cols_group_by;

			state.callReport = initialState.callReport;

			state.tableRows = initialState.tableRows;
			state.tableColumns = initialState.tableColumns;
		},

		setInitialIsSavedReport(state) {
			state.isSavedReport = initialState.isSavedReport;
		},

		// доп строки
		setAdditionalRows: (state, action: PayloadAction<AdditionalRows>) => {
			state.additionalRows.push(action.payload);
		},

		setAdditionalRowValue: (
			state,
			action: PayloadAction<{
				index: number;
				data: OptionType | boolean | OptionType[];
				// option: keyof AdditionalRows;
				option: string;
			}>,
		) => {
			// @ts-ignore
			state.additionalRows[action.payload.index][action.payload.option] = action.payload.data;
		},

		setAdditionalRowObjectValue: (
			state,
			action: PayloadAction<{
				index: number;
				data: OptionType | boolean | OptionType[];
				// option: keyof AdditionalRows;
				optionObject: string;
				option: string;
			}>,
		) => {
			// @ts-ignore
			state.additionalRows[action.payload.index][action.payload.optionObject][action.payload.option] =
				action.payload.data;
		},

		setAllAdditionalRows(state, action: PayloadAction<AdditionalRows[]>) {
			state.additionalRows = action.payload;
		},

		deleteAdditionalRow: (state, action: PayloadAction<{ index: number }>) => {
			state.additionalRows.splice(action.payload.index, 1);
		},
	},
});

export default reportsSlice.reducer;
