import { useEffect, memo } from 'react';

import { useHistory } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import queryString from 'query-string';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { langSlice, userSlice } from 'store';
import { getUserFilter, getUserToken, getUsersWithoutChildren, getMeAccessRights, getMe } from 'store/user/user.slice';

import { getLang } from 'store/lang/lang.slice';
import { WithAdminBar, WithAuthContent, WithMainLoader } from 'containers';
import { getTokens, getTokenWithAuthCheck } from 'utils/tokens';
import { UserType } from './store/user/user.types';

import mixpanel from 'mixpanel-browser';
import { EnvsManager } from './utils/enviroments';

const Routes = memo(() => {
	if (!EnvsManager.isDev && !window.location.host.includes('ppr.imot.io')) {
		// Инициализация Mixpanel только в продакшн среде
		mixpanel.init('8bfe149c16deed5afa6a01e5c4b9ebae', {
			debug: false,
			track_pageview: true,
			persistence: 'localStorage',
		});
	}

	const dispatch = useAppDispatch();
	const history = useHistory();
	const pathArray = history.location.pathname.split('/');
	const search = queryString.parse(history.location.search);
	const hasReloaded = sessionStorage.getItem('hasReloaded'); // Проверяем флаг перед перезагрузкой
	const hasCallsReloaded = sessionStorage.getItem('hasCallsReloaded'); // Проверяем флаг перед перезагрузкой

	const isAuth = useAppSelector((state) => state.user.isAuth);

	const getBaseRequests = (role: string, adminToken: string, token: string) =>
		['admin', 'manager'].includes(role)
			? [dispatch(getMe({ token: adminToken || token })), dispatch(getUsersWithoutChildren())]
			: [dispatch(getMe({ token: adminToken || token }))];

	const getAccessRights = async () => {
		const accessRights = await dispatch(getMeAccessRights());
		if (accessRights) {
			// @ts-ignore
			dispatch(userSlice.actions.setAccessRights(accessRights.payload));
		}
	};

	const { loggedUser } = useAppSelector((state) => state.user);

	// если загрузка, запустить движ
	useEffect(() => {
		const init = async () => {
			if (['ru', 'en', 'es', 'pt'].find((item) => item === pathArray[1]) && isAuth && isAuth !== 'loading') {
				await dispatch(getLang(pathArray[1]));
				dispatch(langSlice.actions.setLang(pathArray[1]));
			}

			const { adminToken } = getTokens();
			// получаем токен пользователя
			// если пользователь неавторизован, то токен берется из url
			// если авторизован - используется токен, полученный при авторизации
			const token = getTokenWithAuthCheck();

			// Если в URL есть isAmoWidget, выполняем дополнительную авторизацию
			if (search.isAmoWidget) {
				dispatch(userSlice.actions.setAuth(true));
				window.sessionStorage.setItem('isAmoWidget', 'true');

				if (!hasReloaded) {
					// Устанавливаем флаг перед перезагрузкой
					sessionStorage.setItem('hasReloaded', 'true');
					// Перезагружаем страницу
					window.location.reload();
					return; // Выходим из функции, чтобы избежать дальнейшего выполнения кода
				}
			}

			// Если в URL есть isAmoWidget, выполняем дополнительную авторизацию + переход(обновление) на страницу звонков
			if (search.isAmoWidget && pathArray.includes('calls')) {
				dispatch(userSlice.actions.setAuth(true));

				history.push(`/${pathArray[1]}/${pathArray[2]}/calls`);

				if (!hasCallsReloaded) {
					// Устанавливаем флаг перед перезагрузкой
					sessionStorage.setItem('hasCallsReloaded', 'true');
					// Перезагружаем страницу
					window.location.reload();
					return; // Выходим из функции, чтобы избежать дальнейшего выполнения кода
				}
			}

			// обновляем токен в хранилище
			window.sessionStorage.setItem('sessionToken', JSON.stringify({ sessionToken: token }));

			const userData = await dispatch(getUserFilter({ sessionToken: adminToken || token }));
			const user = userData?.payload as UserType;

			await dispatch(langSlice.actions.setLang(user?.language));
			await getAccessRights();

			if (token && !user?.error) {
				dispatch(userSlice.actions.setCurrentUser(user));

				if (!EnvsManager.isDev && !window.location.host.includes('ppr.imot.io')) {
					// Инициализация Mixpanel и идентификация пользователя
					mixpanel.identify(loggedUser?.id);
					mixpanel.people.set({
						$name: loggedUser?.name,
						$email: loggedUser?.email,
						$role: loggedUser?.role,
						$timezone: loggedUser?.timezone,
						$language: loggedUser?.language,
					});
				}

				if (!adminToken && ['admin', 'manager'].includes(user?.role))
					await localStorage.setItem('adminToken', JSON.stringify({ adminToken: token }));
				if (adminToken) {
					if (pathArray[2] && pathArray[2] !== '_') {
						const tokenData = await dispatch(getUserToken(pathArray[2]));
						const tokenDataPayload = tokenData.payload;
						if (tokenDataPayload) {
							const localToken: string = tokenDataPayload.access_token;
							const childUserData = await dispatch(getUserFilter({ sessionToken: localToken }));
							const childUser = childUserData.payload as UserType;
							if (childUser) {
								dispatch(userSlice.actions.setChildUser(childUser));
							}
						}
					}
				}

				const baseRequests = getBaseRequests(user?.role, adminToken, token);
				await Promise.all(baseRequests);

				dispatch(userSlice.actions.setAuth(true));
			} else {
				localStorage.clear();
				dispatch(userSlice.actions.setAuth(false));
			}
		};
		if (isAuth === 'loading') {
			init().then();
		}
	}, [isAuth]);

	// если в урле есть authToken, устанавливаю его
	useEffect(() => {
		const authByUrlToken = async () => {
			const urlObj = queryString.parse(history.location.search);
			if (urlObj.authToken) {
				await sessionStorage.setItem('sessionToken', JSON.stringify({ sessionToken: urlObj.authToken }));
			}
		};
		authByUrlToken().then();
	}, []);

	return (
		<WithMainLoader>
			<WithAdminBar>
				<WithAuthContent />
			</WithAdminBar>
		</WithMainLoader>
	);
});

export default Routes;
