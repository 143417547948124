import { Dayjs } from 'dayjs';
import { CriteriasType } from 'store/search/search.types';
import { ICriterias } from 'utils/convertDataForSearchFilters/convertData';
import { ValueObj } from 'utils/optionsCreator';

export type OptionType = {
	value: string;
	label: string;
};
export type PeriodsType =
	| 'yesterday'
	| 'today'
	| 'this_week'
	| 'last_week'
	| 'this_month'
	| 'last_month'
	| 'this_quarter'
	| 'last_quarter'
	| 'this_year'
	| 'last_year'
	| 'all_time';

export type ColsGroupBy = 'tag_name_list' | 'search_items' | 'tag_name_value' | 'calls_count' | '';
export type RowsGroupBy =
	| 'time'
	| 'tag_name_value'
	| 'tag_name_list'
	| 'operator_phone'
	| 'call'
	| 'tag'
	| 'client_phone'
	| '';

export type SavedReportType = {
	id: string;
	name: string;
	period: PeriodsType;
	from_dt: string;
	to_dt: string;
	notification_status?: {
		has_telegram: boolean;
		has_email: boolean;
		telegram_failed: boolean;
		email_failed: boolean;
	};
};

export type CeilInfoType = {
	// количество звонков
	calls_count?: number;
	// минуты
	calls_seconds?: number;
	// проценты
	percent_from_total_count?: number;
	// чек-листы
	checklist_avg?: number;
	// чек-листы в %
	checklist_avg_percent?: number;

	// Время разговора клиента
	client_talk_time?: number;
	// % разговора клиента
	client_talk_time_percentage?: number;
	// Время первой коммуникации
	first_call_dt?: string;
	// Время последней коммуникации
	last_call_dt?: string;
	// Время разговора оператора
	operator_talk_time?: number;
	// % разговора оператора
	operator_talk_time_percentage?: number;
	// Продолжительность тишины
	silence_duration?: number;
	// % продолжительности тишины
	silence_duration_percentage?: number;

	// Кол-во тегов
	tags_count?: number;

	// Телефоны клиентов
	client_phones?: string[];
	// Телефоны сотрудников
	operator_phones?: string[];
	// Сумма баллов
	points?: number;
	// Сумма максимальных баллов
	max_points?: number;
	// Сервисы звонков
	from_services?: string[];
};

export interface CeilAdditionalParams {
	type:
		| 'view_tag_param'
		| 'view_avg_num_tag_param'
		| 'view_checklist_answer_avg_param'
		| 'view_checklist_answer_avg_percent_param'
		| 'view_checklist_avg_param'
		| 'view_sum_num_tag_param'
		| 'view_checklist_avg_percent_param'
		| 'view_checklist_answer_text_avg_param'
		| 'view_percent_calculating_source_param';
	tag_name?: string;
	value?: string[] | number;

	checklist_title?: string;
	question_text?: string;
}

export type KeysSummaries = 'ROW' | 'COL' | 'INTERSECTION';

export type SummariesType = {
	[key: string]: CallReportInfoType;
};

export interface CallReportInfoType extends CeilInfoType {
	call_ids_hash: string | null;
	additional_params?: CeilAdditionalParams[];
}

export type CallReportItemRowType = {
	// всего для строк
	row_all_calls: CallReportInfoType;
	// невошедие для строк
	row_missed_calls: CallReportInfoType;
	// вошедшие для строк
	row_sum_calls: CallReportInfoType;
};

export type CallReportColsInfoType = {
	// всего для колонок
	col_all_calls: CallReportInfoType;
	// невошедие для колонок
	col_missed_calls: CallReportInfoType;
	// вошедшие для колонок
	col_sum_calls: CallReportInfoType;
};

export type DiffReportItemRowType = {
	// всего
	row_all_calls: CeilInfoType;
	// невошедшие
	row_missed_calls: CeilInfoType;
	// вошедшие
	row_sum_calls: CeilInfoType;
};

export type DiifReportCallsCountType = {
	col_all_calls: CeilInfoType;
	col_missed_calls: CeilInfoType;
	col_sum_calls: CeilInfoType;
};

export type CallReportItemValuesType = {
	col_groups: {
		[key: string]: {
			[key: string]: CallReportInfoType;
		};
	};
	row_info: CallReportItemRowType;
};

export type CallReportReportType = {
	cols: {
		group_id: number;
		col_name: string;
	}[];
	cols_info: {
		[key: string]: {
			[key: string]: CallReportColsInfoType;
		};
	};
	filtered_calls_count: number;
	row_group_header: string;
	rows: string[];
	total_calls_count: number;
	values: { [key: string]: CallReportItemValuesType };

	intersection_of_summaries: Record<KeysSummaries, SummariesType> | null;
};

export type CallReportType = {
	report: CallReportReportType;
	report_hash: string;
	report_parameters_hash: string;
};

export interface ReportByHash {
	start_date: string;
	end_date: string;
	report_parameters: ReportParametersType;
}

export type DiffReportItemValuesType = {
	col_groups: {
		[key: string]: {
			[key: string]: CeilInfoType;
		};
	};
	row_info: DiffReportItemRowType;
};

export type DiffReportType = {
	cols_info: {
		[key: string]: {
			[key: string]: DiifReportCallsCountType;
		};
	};
	rows: { [key: string]: DiffReportItemValuesType };
};

export type ChartType = 'histogram' | 'line_chart';

export interface ViewOptionsGlobalCharts {
	// keyof CeilInfoType
	by_sub_column: string;
	chart_type: ChartType;
}
export interface ViewOptionsGlobal {
	show_table: boolean;
	show_chart: boolean;
	show_diff_report: boolean;
	show_zero_rows: boolean;
	show_zero_cols: boolean;
	show_zero_grouping_rows: boolean;
	show_zero_grouping_cols: boolean;
	show_row_calls_sum: boolean;
	show_col_calls_sum: boolean;
	show_row_all_calls_sum: boolean;
	show_col_all_calls_sum: boolean;
	show_row_calls_missed: boolean;
	show_col_calls_missed: boolean;
	intersection_summaries_type: KeysSummaries;
}

export interface ViewOptionsGlobalWithCharts extends ViewOptionsGlobal {
	chart_options: ViewOptionsGlobalCharts;
}

export interface AddParamType {
	param_type: ViewAdditionalParams;
}

// тег
export interface AddParamTagName extends AddParamType {
	tag_name: string;
}

// среднее значение числового тега
export interface AddParamAvgNumTagName extends AddParamType {
	tag_name: string;
}

// вопрос чек листа
export interface AddParamChecklistQuestionLocal extends AddParamType {
	checklists: OptionType[];
}

export interface AddParamChecklistQuestion extends AddParamType {
	checklist_id: string;
	question_id: string;
}

// чек лист
export interface AddParamChecklistLocal extends AddParamType {
	checklists: OptionType[];
}

export interface AddParamChecklist extends AddParamType {
	checklist_id: string;
}

// комментарий
export interface AddParamComment extends AddParamType {
	comment_title: string;
}
export interface AddParamCommentLocal extends AddParamType {
	comment_title: OptionType[];
}

// источник 100% (фильтры как 100%)
export interface AddParamPercentSourceLocal extends AddParamType {
	title: string;
	search_items: CriteriasType[];
}

export interface AddParamPercentSource extends AddParamType {
	title: string;
	search_items: ICriterias[];
}

export interface ViewOptionsOnly {
	show_calls_count: boolean;
	show_minutes: boolean;
	show_percentage: boolean;
	show_operator_time: boolean;
	show_percentage_from_all_calls_row: boolean;
	show_percentage_from_all_calls_col: boolean;
	show_percentage_from_sum_calls_row: boolean;
	show_percentage_from_sum_calls_col: boolean;
	show_client_time: boolean;
	show_silence_time: boolean;
	show_operator_time_percentage: boolean;
	show_client_time_percentage: boolean;
	show_silence_time_percentage: boolean;
	// show_call_dt - устаревший, но хранится в старых отчетах
	// разбивается на 2 галки show_first_call_dt и show_last_call_dt в рамках задачи DEV-2786
	show_call_dt: boolean;
	show_first_call_dt: boolean;
	show_last_call_dt: boolean;
	show_tags_count: boolean;
	show_deals: boolean;
	show_checklist_average: boolean;
	show_checklist_average_percent: boolean;
	show_client_phones: boolean;
	show_operator_phones: boolean;
	show_points: boolean;
	show_max_points: boolean;
	show_from_services: boolean;
}

export interface ViewOptionsRowsOrColumnsResponse extends ViewOptionsOnly {
	additional_params: (
		| AddParamTagName
		| AddParamAvgNumTagName
		| AddParamChecklistQuestion
		| AddParamChecklist
		| AddParamComment
		| AddParamPercentSource
	)[];
}

export interface ViewOptionsRowsOrColumns extends ViewOptionsOnly {
	additional_params: (
		| AddParamTagName
		| AddParamAvgNumTagName
		| AddParamChecklistQuestionLocal
		| AddParamChecklistLocal
		| AddParamCommentLocal
		| AddParamPercentSourceLocal
	)[];
}

export type TagNameValueValueType = {
	name: string;
	values?: string[];
	split?: boolean;
	only_to_first_appropriate: boolean;
};

export type RowsGroupByParametersTypeResponse = {
	group_by: RowsGroupBy;
	value: string | null | TagNameValueValueType;
	view_options: ViewOptionsRowsOrColumnsResponse;
};

export type RowsGroupByParametersType = {
	group_by: RowsGroupBy;
	value: string | null | TagNameValueValueType;
	view_options: ViewOptionsRowsOrColumns;
};

export type SearchItemsValueType = {
	col_name: string;
	search_items: [
		{
			key: string;
			values: [];
		},
	];
};

export type TagNameListValueType = {
	values: string[];
	split: boolean;
	col_name: string;
	only_to_first_appropriate: boolean;
};

export type TagNameListValueRowType = {
	values: string[];
	split: boolean;
	row_name: string;
	only_to_first_appropriate: boolean;
};

export type ColsGroupByParametersType = {
	group_by: ColsGroupBy;
	value?: string | string[] | SearchItemsValueType | TagNameValueValueType | TagNameListValueType;
	view_options: ViewOptionsRowsOrColumns;
};

export type ReportParametersType = {
	report_name: string;
	report_type: string;
	period: PeriodsType | null | string;
	from_dt: string | null;
	to_dt: string | null;
	view_options: ViewOptionsGlobalWithCharts;
	rows_group_by: RowsGroupByParametersType[];
	cols_group_by: AdditionalColumns[];
	call_search_items: [];
};

export interface ReportParametersTypeResponse extends Omit<ReportParametersType, 'rows_group_by'> {
	rows_group_by: RowsGroupByParametersTypeResponse[];
}

export const enum ViewAdditionalParams {
	view_tag_param = 'view_tag_param',
	view_avg_num_tag_param = 'view_avg_num_tag_param',
	view_sum_num_tag_param = 'view_sum_num_tag_param',
	view_checklist_answer_avg_param = 'view_checklist_answer_avg_param',
	view_checklist_answer_avg_percent_param = 'view_checklist_answer_avg_percent_param',
	view_checklist_answer_text_avg_param = 'view_checklist_answer_text_avg_param',
	view_checklist_avg_param = 'view_checklist_avg_param',
	view_checklist_avg_percent_param = 'view_checklist_avg_percent_param',
	view_comment_param = 'view_comment_param',
	view_percent_calculating_source_param = 'view_percent_calculating_source_param',
}

export type SelectorsValuesParametersType = {
	cols_groupings: string[];
	groupings_by_time: string[];
	report_types: string[];
	rows_groupings: string[];
	view_additional_params: ViewAdditionalParams[];
};

export type AdditionalColumns = {
	// выбранное значение в селекте
	groupByColumnsValue: ValueObj;

	// по ссписку тегов
	tagsNameList: {
		values: [];
		values_isValid: boolean;
		split: boolean;
		col_name: string;
		col_name_isValid: boolean;
		only_to_first_appropriate: boolean;
	};
	// по тегу
	tagNameValue: {
		valueTag: OptionType;
		optionsTag: OptionType[];
		values: [];
		name: string;
		isValid: boolean;
		split: boolean;
		only_to_first_appropriate: boolean;
	};
	// точный фильтр
	callFilters: {
		options: [];
		values: any;
		activeValues: CriteriasType[];
		nameColumn: {
			// переименовать на col_name
			value: string;
			isValid: boolean;
		};
	};
	viewOptions: ViewOptionsRowsOrColumns;
};

// TODO: привести к общему виду красивому, аналогично столбцам!
export interface AdditionalRows {
	// выбранное значение в селекте
	groupByRowsValue: ValueObj;

	/* по времени */

	// выбранное значение времени
	timeRowsReportValue: ValueObj;

	/* по тегу и значению */

	// тег в "по тегу и значениям"
	tagNameValueRow: OptionType;
	// значения тега  в "по тегу и значениям"
	tagNameRowListWithValue: OptionType[];
	// чек бокс в "по тегу и значениям"
	checkboxSplitRow: boolean;
	// список значений выбранного тега в "по тегу и значениям"
	tagValuesRow: ValueObj[];
	// чек бокс до первого совпадения
	checkboxToFirstAppropriate: boolean;
	// валидация тега
	tagNameValueValid: boolean;

	/* по списку тегов */
	tagsNameList: {
		values: [];
		values_isValid: boolean;
		split: boolean;
		row_name: string;
		row_name_isValid: boolean;
		only_to_first_appropriate: boolean;
	};

	viewOptions: ViewOptionsRowsOrColumns;
}

export interface SelectNamesType {
	[key: string]: {
		label: string;
	};
}

export type StatusTypes = 'progress' | 'done';
export type StageTypes =
	| 'getting_call_list'
	| 'call_processing'
	| 'col_processing'
	| 'row_processing'
	| 'summary_processing'
	| 'intersection_of_summaries'
	| 'delete_empty_rows'
	| 'rows_normalization'
	| 'delete_empty_normalized_rows';

export interface LoadingReport {
	status: StatusTypes | '';
	stage_name: StageTypes | '';
	stage_idx: number;
	done: number;
	total: number;
	done_part: number;
	report_result?: CallReportType;
}

export type ReportInitialStateType = {
	allReports: SavedReportType[];
	currentSavedReport: OptionType | null;
	isSavedReport: boolean;
	isReportByHash: boolean;
	isReportById: boolean;
	isReportCollapsed: boolean;
	// если у отчета больше reportColsLimit (25), отчет не рисуется, рисуется кнопка скачать в эксель
	isReportTooBig: boolean;
	// полученный отчет, отображаемый в таблице
	callReport: CallReportType;
	// отчет разницы
	diffReport: DiffReportType;
	// флаг загрузки разницы
	diffReportIsLoaded: boolean;
	// скрытие галочки разницы
	isDiffReportHide: boolean;
	// текущий отчет с параметрами
	activeReport: ReportParametersType;
	// дополнительные строки
	additionalRows: AdditionalRows[];
	view_options: ViewOptionsRowsOrColumns;

	reportWebSocket: any;

	date: (Dayjs | null)[];
	selectors: SelectorsValuesParametersType;
	tagNames: string[];
	numTagsNames: string[];
	tagNamesWithValues: string[];

	tagRowValues: string[];
	tagColumnValues: string[];
	tagColumnsAddValues: string[];
	tableRows: [];
	tableColumns: [];
	tableAllRows: [];
	isResetParameters: boolean;
	isLoading: boolean;
	reportLoadingIsStarted: boolean;
	loadingReport: LoadingReport;
	isValidTagList: boolean;
};

export const enum Periods {
	yesterday = 'yesterday',
	today = 'today',
	this_week = 'this_week',
	last_week = 'last_week',
	this_month = 'this_month',
	last_month = 'last_month',
	this_quarter = 'this_quarter',
	last_quarter = 'last_quarter',
	this_year = 'this_year',
	last_year = 'last_year',
	all_time = 'all_time',
}

export const enum ViewOptions {
	// для таблицы
	show_table = 'show_table',
	show_chart = 'show_chart',
	show_diff_report = 'show_diff_report',
	show_zero_rows = 'show_zero_rows',
	show_zero_cols = 'show_zero_cols',
	show_zero_grouping_rows = 'show_zero_grouping_rows',
	show_zero_grouping_cols = 'show_zero_grouping_cols',
	show_row_calls_sum = 'show_row_calls_sum',
	show_col_calls_sum = 'show_col_calls_sum',
	show_row_all_calls_sum = 'show_row_all_calls_sum',
	show_col_all_calls_sum = 'show_col_all_calls_sum',
	show_row_calls_missed = 'show_row_calls_missed',
	show_col_calls_missed = 'show_col_calls_missed',

	// строки и колонки
	show_calls_count = 'show_calls_count',
	show_minutes = 'show_minutes',
	show_percentage = 'show_percentage',
	show_percentage_from_all_calls_row = 'show_percentage_from_all_calls_row',
	show_percentage_from_all_calls_col = 'show_percentage_from_all_calls_col',
	show_percentage_from_sum_calls_row = 'show_percentage_from_sum_calls_row',
	show_percentage_from_sum_calls_col = 'show_percentage_from_sum_calls_col',
	show_client_time = 'show_client_time',
	show_call_dt = 'show_call_dt',
	show_first_call_dt = 'show_first_call_dt',
	show_last_call_dt = 'show_last_call_dt',
	show_operator_time = 'show_operator_time',
	show_silence_time = 'show_silence_time',
	show_operator_time_percentage = 'show_operator_time_percentage',
	show_client_time_percentage = 'show_client_time_percentage',
	show_silence_time_percentage = 'show_silence_time_percentage',
	show_tags_count = 'show_tags_count',
	show_checklist_average = 'show_checklist_average',
	show_checklist_average_percent = 'show_checklist_average_percent',
	show_client_phones = 'show_client_phones',
	show_operator_phones = 'show_operator_phones',
	show_points = 'show_points',
	show_max_points = 'show_max_points',
	show_from_services = 'show_from_services',
}
