import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { reportsSlice, searchSlice } from 'store';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { translate } from 'localizations';
import { RootState } from 'store/store';
import { getReport } from 'store/reports/actions';
import { convertDataForReports } from 'utils/convertDataForSearchFilters/convertData';
import { ValueObj, optionsCreatorWithName } from 'utils/optionsCreator';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { OptionType, Periods, PeriodsType } from 'store/reports/reports.types';
import { criteriaWithComplexValues } from 'configs/searchCriteria';
import { RouteParams } from '../types/ReportsTypes';
import { convertSavedReport } from '../ReportsFunctions/convertData';
import { useReportTranslations } from './useReportTranslations';
import useZeroParameters from './useZeroParameters';
import { searchServiceAPI } from 'services/searchService';

// Для загрузки отчета при переходе по ссылке (c id отчета)
// или переключении шаблонов / сохраненных отчетов в селекте
const useMakeReportById = () => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useAppDispatch();
	const { language } = useAppSelector((state: RootState) => state.lang);
	const { reportId } = useParams<RouteParams>();
	const { allReports, isLoading } = useAppSelector((state) => state.reports);
	const allCriterias = useAppSelector((state) => state.search.allCriterias);
	const { selectTitlesOptions } = useReportTranslations();
	const [getDateRangeByPeriod] = searchServiceAPI.useLazyGetDateRangeByPeriodQuery();

	const currentReportId = useRef<string>();
	currentReportId.current = '';

	const savedReportsOptions = optionsCreatorWithName(allReports);

	const { resetTable } = useZeroParameters();

	// начать загрузку отчета по вебсокету
	const startLoadingReport = () => {
		dispatch(reportsSlice.actions.setLoading(true));
		resetTable();
	};

	// найти отчет по id
	const getSavedReportById = async (report: ValueObj) => {
		dispatch(reportsSlice.actions.setCurrentSavedReport(report));
		dispatch(reportsSlice.actions.setReportById(true));
		const reportById = await dispatch(getReport(report.value));

		if (reportById && reportById.meta.requestStatus !== 'rejected') {
			const {
				report: convertedReport,
				additionalRows,
				additionalColumns,
				callSearch,
			} = convertSavedReport(
				convertDataForReports(reportById.payload),
				selectTitlesOptions,
				allCriterias,
				criteriaWithComplexValues,
			);
			// @ts-ignore поправить типы позже
			dispatch(reportsSlice.actions.setActiveReport(convertedReport));
			dispatch(reportsSlice.actions.setAllAdditionalColumns(additionalColumns));
			dispatch(reportsSlice.actions.setAllAdditionalRows(additionalRows));
			dispatch(
				searchSlice.actions.setActiveCriterias({
					page: 'reports',
					data: callSearch,
				}),
			);

			const result = reportById.payload;
			if (result.period) {
				if (result.period !== Periods['all_time'])
					// запрашиваем даты
					await getDateRangeByPeriod({ period: result.period as PeriodsType, page: 'reports' });

				// если отчет за все время, скрываем чекбокс разницы с прошлым периодом
				if (result.period === Periods['all_time']) dispatch(reportsSlice.actions.setDiffReportHide(true));
			} else {
				dispatch(reportsSlice.actions.setPeriod(null));
			}

			startLoadingReport();
		} else {
			enqueueSnackbar(
				null,
				createSnackbarOptions({
					time: 3000,
					type: 'error',
					text: `${translate('reportErrorMake', language)}`,
				}),
			);
		}
	};

	// если в урле есть id отчета, то находим этот отчет
	// и начинаем загрузку
	useEffect(() => {
		if (reportId && allReports.length && currentReportId.current !== reportId) {
			const foundReport = savedReportsOptions.find((report) => report.value === reportId);
			if (!foundReport) {
				enqueueSnackbar(
					null,
					createSnackbarOptions({
						time: 3000,
						type: 'error',
						text: `${translate('reportErrorMake', language)}`,
					}),
				);
			} else {
				dispatch(reportsSlice.actions.setCurrentSavedReport(foundReport as OptionType));
				getSavedReportById(foundReport as ValueObj);
				currentReportId.current = reportId;
			}
		}
	}, []);

	return { getSavedReportById, startLoadingReport };
};

export default useMakeReportById;
