import { Redirect } from 'react-router-dom';

import withProps from './withProps';
import withSTTProps from './withSTTProps';
import lazyWithRetry from './lazyWithRetry';

const Calls = lazyWithRetry(() => import('pages/Calls/CallsPage'));
const CallPage = lazyWithRetry(() => import('pages/Call/CallPage'));
const Reports = lazyWithRetry(() => import('pages/Reports/Reports'));
const ReportPage = lazyWithRetry(() => import('pages/Reports/ReportPage/ReportPage'));
const ReportsListPage = lazyWithRetry(() => import('pages/Reports/ReportsListPage/ReportsListPage'));
const Auth = lazyWithRetry(() => import('pages/Auth/Auth'));
const PageNotFound = lazyWithRetry(() => import('pages/PageNotFound/PageNotFound'));
const MarkupRules = lazyWithRetry(() => import('pages/MarkupRules/MarkupRules'));
const Users = lazyWithRetry(() => import('pages/Users/Users'));
const CallRoute = lazyWithRetry(() => import('pages/OneCallRoute/OneCallRoute'));
const Quotas = lazyWithRetry(() => import('pages/Settings/Quotas/Quotas'));
const Settings = lazyWithRetry(() => import('pages/Settings/Settings'));
const AddressBook = lazyWithRetry(() => import('pages/Settings/AddressBook/AddressBook'));
const UserIntegrationsList = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/UserIntegrationsList/UserIntegrationsList'),
);
const IntegrationsList = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsList/IntegrationsList'),
);
const TranslationIntegrationsTags = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/TranslationIntegrationsTags/TranslationIntegrationsTags'),
);
const ApiTokens = lazyWithRetry(() => import('pages/Settings/Integrations/components/ApiTokens/ApiTokens'));
const IntegrationsSettings = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsSettings/IntegrationsSettings'),
);
const AccessKeysTab = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsSettings/components/AccessKeysTab/AccessKeysTab'),
);
const Parameters = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsSettings/components/Parameters/Parameters'),
);
const FilterRules = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsSettings/components/FilterRules/FilterRules'),
);
const CallsActions = lazyWithRetry(() => import('pages/Settings/CallsActions/CallsActionsSettings'));
const STTSettings = lazyWithRetry(() => import('pages/Settings/STTSettings/STTSettings'));
const Integrations = lazyWithRetry(() => import('pages/Settings/Integrations/Integrations'));
const Modules = lazyWithRetry(() => import('pages/Settings/Modules/Modules'));
const UserPersonalInfo = lazyWithRetry(() => import('features/UserPersonalInfo/UserPersonalInfo'));
const Employees = lazyWithRetry(() => import('widgets/Employees/Employees'));
const EmployeeProfile = lazyWithRetry(() => import('features/EmployeeProfile/EmployeeProfile'));
const EmployeePersonalInfo = lazyWithRetry(() => import('features/EmployeeProfile/EmployeePersonalInfo'));
const EmployeeAccessRights = lazyWithRetry(() => import('features/EmployeeProfile/EmployeeAccessRights'));
const EmployeeReportsLimits = lazyWithRetry(() => import('features/EmployeeProfile/EmployeeReportsLimits'));
const EmployeeCallsLimits = lazyWithRetry(() => import('features/EmployeeProfile/EmployeeCallsLimits'));
const UserReportsLimits = lazyWithRetry(() => import('features/UserPermissions/UserReportsLimits'));
const UserAccessRights = lazyWithRetry(() => import('features/UserPermissions/UserAccessRights'));
const UserCallsLimits = lazyWithRetry(() => import('features/UserPermissions/UserCallsLimits'));
const ManagerUsers = lazyWithRetry(() => import('features/UserPermissions/ManagerUsers'));
const Notifications = lazyWithRetry(() => import('pages/Notifications/Notifications'));
const CallWithGPT = lazyWithRetry(() => import('pages/Call/CallWithGPT'));
const CallQuotas = lazyWithRetry(() => import('pages/Settings/Quotas/components/CallQuotas/CallQuotas'));
const GPTQuotas = lazyWithRetry(() => import('pages/Settings/Quotas/components/GPTQuotas/GPTQuotas'));
const ServiceConsumptionHistory = lazyWithRetry(
	() => import('pages/Settings/ServiceConsumptionHistory/ui/ServiceConsumptionHistory'),
);
const AudioCommunications = lazyWithRetry(
	() => import('pages/Settings/ServiceConsumptionHistory/components/AudioCommunications/ui/AudioCommunications'),
);
const GPTCommunications = lazyWithRetry(
	() => import('pages/Settings/ServiceConsumptionHistory/components/GPTCommunications/ui/GPTCommunications'),
);
const DialogsCommunications = lazyWithRetry(
	() => import('pages/Settings/ServiceConsumptionHistory/components/DialogsCommunications/ui/DialogsCommunications'),
);
const Billing = lazyWithRetry(() => import('pages/Settings/Billing/ui/Billing'));
const AboutRates = lazyWithRetry(() => import('pages/Settings/Billing/components/AboutRates/AboutRates'));
const Writeoffs = lazyWithRetry(() => import('pages/Settings/Billing/components/Writeoffs/Writeoffs'));
const Payments = lazyWithRetry(() => import('pages/Settings/Billing/components/Payments/Payments'));

const basePath = '/:lang/:userId';
const integrationsSettingsPath = 'settings/integrations/connected-integrations/integration-settings';

export const routes = [
	{
		path: `${basePath}/calls`,
		component: Calls,
		exact: true,
	},
	{
		path: `${basePath}/reports`,
		component: ReportsListPage,
		exact: true,
	},
	{
		path: `${basePath}/report/create`,
		component: withProps(Reports),
		exact: true,
	},
	{
		path: `${basePath}/report/:reportId`,
		component: withProps(Reports),
		exact: true,
	},
	{
		path: `${basePath}/report`,
		component: withProps(ReportPage),
		exact: true,
	},
	{
		path: `${basePath}/tags`,
		component: MarkupRules,
		exact: true,
	},
	{
		path: `${basePath}/dictionaries`,
		component: MarkupRules,
		exact: true,
	},
	{
		path: `${basePath}/checklists`,
		component: MarkupRules,
		exact: true,
	},
	{
		path: `${basePath}/gpt`,
		component: MarkupRules,
		exact: true,
	},
	{
		path: `${basePath}/users`,
		component: Users,
		exact: true,
	},
	{
		path: `${basePath}/employees`,
		component: () => <Redirect to={`${basePath}/settings/employees`} />,
		exact: true,
	},
	{
		path: `${basePath}/employees/:employeeId`,
		component: withProps(EmployeeProfile),
		routes: [
			{
				path: `${basePath}/employees/:employeeId/profile`,
				component: withProps(EmployeePersonalInfo),
			},
			{
				path: `${basePath}/employees/:employeeId/access-rights`,
				component: withProps(EmployeeAccessRights),
			},
			{
				path: `${basePath}/employees/:employeeId/calls-limits`,
				component: withProps(EmployeeCallsLimits),
			},
			{
				path: `${basePath}/employees/:employeeId/reports`,
				component: withProps(EmployeeReportsLimits),
			},
		],
	},
	{
		path: `${basePath}/notifications`,
		component: withProps(Notifications),
	},
	{
		path: `${basePath}/settings`,
		component: Settings,
		routes: [
			{
				path: `${basePath}/settings/modules`,
				component: withProps(Modules),
			},
			{
				path: `${basePath}/settings/profile`,
				component: withProps(UserPersonalInfo),
			},
			{
				path: `${basePath}/settings/access-rights`,
				component: withProps(UserAccessRights),
			},
			{
				path: `${basePath}/settings/calls-limits`,
				component: withProps(UserCallsLimits),
			},
			{
				path: `${basePath}/settings/reports`,
				component: withProps(UserReportsLimits),
			},
			{
				path: `${basePath}/settings/manager-users`,
				component: withProps(ManagerUsers),
			},
			{
				path: `${basePath}/settings/employees`,
				component: withProps(Employees),
			},
			{
				path: `${basePath}/settings/actions-with-calls`,
				component: withProps(CallsActions),
			},
			{
				path: `${basePath}/settings/word-processing`,
				component: withSTTProps(STTSettings),
			},
			{
				path: `${basePath}/settings/quotas`,
				component: Quotas,
				routes: [
					{
						path: `${basePath}/settings/quotas/call-quotas`,
						component: CallQuotas,
					},
					{
						path: `${basePath}/settings/quotas/gpt-quotas`,
						component: GPTQuotas,
					},
				],
			},
			{
				path: `${basePath}/settings/service-consumption-history`,
				component: ServiceConsumptionHistory,
				routes: [
					{
						path: `${basePath}/settings/service-consumption-history/audio`,
						component: AudioCommunications,
					},
					{
						path: `${basePath}/settings/service-consumption-history/gpt`,
						component: GPTCommunications,
					},
					{
						path: `${basePath}/settings/service-consumption-history/dialogs`,
						component: DialogsCommunications,
					},
				],
			},
			{
				path: `${basePath}/settings/billing`,
				component: Billing,
				routes: [
					{
						path: `${basePath}/settings/billing/aboutRates`,
						component: AboutRates,
					},
					{
						path: `${basePath}/settings/billing/writeoffs`,
						component: Writeoffs,
					},
					{
						path: `${basePath}/settings/billing/payments`,
						component: Payments,
					},
				],
			},
			{
				path: `${basePath}/settings/address-book`,
				component: AddressBook,
			},
			{
				path: `${basePath}/settings/integrations`,
				component: Integrations,
				routes: [
					{
						path: `${basePath}/settings/integrations/connected-integrations`,
						component: UserIntegrationsList,
						routes: [
							{
								path: `${basePath}/${integrationsSettingsPath}/:integrationId`,
								component: IntegrationsSettings,
								routes: [
									{
										path: `${basePath}/${integrationsSettingsPath}/:integrationId/access-keys`,
										component: AccessKeysTab,
										exact: true,
									},
									{
										path: `${basePath}/${integrationsSettingsPath}/:integrationId/parameters`,
										component: Parameters,
										exact: true,
									},
									{
										path: `${basePath}/${integrationsSettingsPath}/:integrationId/filter-rules`,
										component: FilterRules,
										exact: true,
									},
								],
							},
						],
					},
					{
						path: `${basePath}/settings/integrations/all-integrations`,
						component: IntegrationsList,
						exact: true,
					},
					{
						path: `${basePath}/settings/integrations/translation-integrations-tags`,
						component: TranslationIntegrationsTags,
						exact: true,
					},
					{
						path: `${basePath}/settings/integrations/api-tokens`,
						component: ApiTokens,
						exact: true,
					},
				],
			},
		],
	},
	{
		path: `${basePath}/call`,
		component: CallPage,
		exact: true,
	},
	{
		path: `${basePath}/call/gpt`,
		component: CallWithGPT,
		exact: true,
	},
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/lang/_/calls" />,
	},
	{
		path: '/:lang',
		exact: true,
		component: () => <Redirect to="/lang/_/calls" />,
	},
	{
		path: '/:lang/auth',
		component: () => <Redirect to="/lang/_/calls" />,
	},
	{
		path: '*',
		component: PageNotFound,
	},
	{
		path: '/:lang/404',
		component: PageNotFound,
	},
];

export const authRoutes = [
	{
		path: '/:lang/auth',
		component: Auth,
	},
	{
		path: `${basePath}/call`,
		component: CallRoute,
	},
	{
		path: '*',
		component: Auth,
	},
];
